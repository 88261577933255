import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Link from 'components/UI/Link';
import GeneralSettings from 'helpers/constants/settings';
import {
  getDownloadAppLink, getOnoffB2BLink, stringContainsWords, FlattenJson,
} from 'helpers/utils/url';
import { isMobileOS, isTablet } from 'helpers/utils/mobile';
import { getLocale, Locales } from 'helpers/utils/locale';
import { flatten } from 'flat';
import Menu from 'helpers/constants/menuConstants.json';

import hiringEn from 'images/header/hiringEn.png';
import hiringFr from 'images/header/hiringFr.png';
import logo from 'images/logos/logo.svg';

import HeaderMenu from './Menu';
import HeaderMenuMobile from './MobileMenu';
import styles from './Header.module.scss';

const ViewPortWidthCollapse = GeneralSettings.MOBILE_VIEW.HEADER_MENU; // px

const Header: React.FC = () => {
  const { locale } = useIntl();
  const { LEGAL } = Menu;
  const flattenMenu: FlattenJson = flatten(Menu);
  // only once else cause flicker
  const Settings = GeneralSettings.DESKTOP_MENU;
  const [screenWidth, setScreenWidth] = useState(1024);
  const [isMobile, setMobile] = useState(false);
  const [isSmallLogo, setSmallLogo] = useState(false);
  const jobsOfferLink = getLocale(locale) === Locales.En
    ? LEGAL.JOBS_OFFER_EN : LEGAL.JOBS_OFFER_FR;
  const downloadLink = getDownloadAppLink(locale);
  const b2bLink = getOnoffB2BLink(locale);
  const featuresLink = flattenMenu[`HEADER.FEATURES.URL.${locale}`];
  const pricingLink = flattenMenu[`HEADER.PRICING.URL.${locale}`];
  const faqLink = flattenMenu[`HEADER.FAQ.URL.${locale}`];
  const aboutLink = flattenMenu[`HEADER.WHOWEARE.URL.${locale}`];
  const [displayHiringLabel, setDisplayHiringLabel] = useState(false);

  const updateWidth = (): void => {
    setScreenWidth(window.innerWidth);
    setSmallLogo(window.innerWidth > Settings.SMALL_LOGO_MIN
      && window.innerWidth < Settings.SMALL_LOGO_MAX);
    setMobile(isMobileOS() && !isTablet());
  };

  useEffect(() => {
    updateWidth();
    setDisplayHiringLabel(stringContainsWords(window.location.href, 'home', 'accueil'));
    window.addEventListener('resize', updateWidth);
    return (): void => window.removeEventListener('resize', updateWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {!isMobile && screenWidth > ViewPortWidthCollapse ? (
        <header className={styles.container}>
          <div className={styles.header}>
            <div className={styles.logodiv}>
              {displayHiringLabel ? (
                <a href={jobsOfferLink}>
                  <img className={styles.logo} src={logo} alt="onoff logo" />
                  <img className={styles.hiring} src={getLocale(locale) === Locales.En ? hiringEn : hiringFr} alt="hiring link" />
                </a>
              ) : (
                <Link to="/">
                  <img className={styles.logo} src={logo} alt="onoff logo" />
                </Link>
              )}
            </div>
            <div className={styles.desktopMenuItems}>
              <HeaderMenu
                isSmallLogo={isSmallLogo}
                downloadLink={downloadLink}
                b2bLink={b2bLink}
                featuresLink={featuresLink}
                pricingLink={pricingLink}
                faqLink={faqLink}
                aboutLink={aboutLink}
              />
            </div>
          </div>
        </header>
      ) : (
        <HeaderMenuMobile
          isSmallLogo={isSmallLogo}
          downloadLink={downloadLink}
          b2bLink={b2bLink}
          featuresLink={featuresLink}
          pricingLink={pricingLink}
          faqLink={faqLink}
          aboutLink={aboutLink}
        />
      )}
    </>
  );
};

export default Header;
