import React from 'react';
import { IntlProvider } from 'react-intl';
import { flatten } from 'flat';

import messagesFr from 'locales/fr.json';
import messagesEn from 'locales/en.json';
import Header from '../Header';
import Footer from '../Footer';

import styles from './Layout.module.scss';

export interface IndexProps {
  pageContext: Record<string, string>;
}
const messages: Record<string, object> = {
  fr: messagesFr,
  en: messagesEn,
};

const Index: React.FC<IndexProps> = ({ children, pageContext }) => (
  <>
    <IntlProvider
      locale={pageContext.language}
      messages={flatten(messages[pageContext.language])}
      defaultLocale="en"
    >
      <Header />
      <main className={styles.main}>{children}</main>
      <Footer />
    </IntlProvider>
  </>
);

export default Index;
