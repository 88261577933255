import React, { useEffect, useRef } from 'react';

export const useOutsideClickHandler = (
  onClickOutside: (event: MouseEvent) => void,
  ref: React.RefObject<HTMLElement>,
): void => {
  const handleClick = (event: MouseEvent): void => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      onClickOutside(event);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return (): void => {
      document.removeEventListener('mousedown', handleClick);
    };
  });
};

export const useInterval = (callback: Function, delay: number | null): void => {
  const savedCallback = useRef<Function>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = (): void => savedCallback && savedCallback.current && savedCallback.current();

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return (): void => clearInterval(id);
    }

    return (): void => { /* placeholder */ };
  }, [delay]);
};
