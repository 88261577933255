export default {
  SOCIAL: {
    FACEBOOK: 'https://www.facebook.com/onoffapp' as const,
    TWITTER: 'https://twitter.com/onoff' as const,
    LINKEDIN: 'https://www.linkedin.com/company/onofftelecom' as const,
  },
  BANNERS: {
    TRY_FREE: 'https://web.onoff.app/login',
    DEMO: 'https://calendly.com/onoff-business/presentation-onoff-business?month=2020-04',
  },
  PAGES: {
    GROUP_MAIN: 'https://www.onoff.app/home/' as const,
    PRICING_TRY_FREE: 'https://web.onoff.app/login' as const,
    ONOFF_BUSINESS: {
      EN: 'https://onoffbusiness.com/home/' as const,
      FR: 'https://onoffbusiness.com/accueil/' as const,
    },
    DOWNLOAD_APP: {
      EN: 'https://onoff.app.link/organic/en' as const,
      FR: 'https://onoff.app.link/organic' as const,
    },
    LOGIN: 'https://web.onoff.app/login' as const,
  },
  LEGAL: {
    COOKIES_POLICY: 'https://www.onoff.app/privacy-policy/' as const,
  },
};
