import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { getLocale, Locales } from 'helpers/utils/locale';

import favicon from 'images/favicon.png';
import socialPreviewEn from 'images/social-preview-en.jpg';
import socialPreviewFr from 'images/social-preview-fr.jpg';
import { graphql, useStaticQuery } from 'gatsby';

interface SEOProps {
  pageTitleTermPath: string;
  description?: string;
}

const SEO: React.FC<SEOProps> = ({ pageTitleTermPath, description }) => {
  const intl = useIntl();
  const pageTitle = `${intl.formatMessage({ id: `pages.${pageTitleTermPath}.title` })} | ${intl.formatMessage({ id: 'meta.title' })}`;
  const metaTitle = intl.formatMessage({ id: 'meta.metaTitle' });
  const metaDescription = description || intl.formatMessage({ id: 'meta.metaDescription' });
  const metaImg = getLocale(intl.locale) === Locales.En ? socialPreviewEn : socialPreviewFr;
  const { site } = useStaticQuery(graphql`
  query PageQuery {
    site {
      siteMetadata {
        siteURL
      }
    }
  }
`);

  const chooseCookies = (
    <script>
      {`
      //Le sdk qui permet de lancer le widget Axeptio et de sauvegarder les acceptations
    window.onload = function() {
      var el = document.createElement('script');
    el.setAttribute('src', 'https://static.axept.io/sdk-slim.js');
    el.setAttribute('type', 'text/javascript');
    el.setAttribute('async', true);
    el.setAttribute('data-id', '5fed22ad5558222597c41e71');
    if (document.body !== null) {
      document.body.appendChild(el);
  }
   if (document.documentElement.lang.includes('en')){
    el.setAttribute('data-cookies-version', 'onoff b2c-base_Cp');
  }
  if (document.documentElement.lang.includes('fr')){
    el.setAttribute('data-cookies-version', 'onoff b2c-base');
  }
}

// La fonction qui permet de lancer Google Analytics
function launchGoogleAnalytics(){
      (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
          (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date(); a = s.createElement(o),
          m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
      })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
    ga('create', 'UA-124590402-1', 'auto');
    ga('send', 'pageview');
}

// La fonction qui permet de lancer le Facebook Pixel
function launchFB(){
      !function (f, b, e, v, n, t, s) {
        if (f.fbq) return; n = f.fbq = function () {
          n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }; if (!f._fbq) f._fbq = n;
        n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
        t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
      }(window,
        document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

  fbq('init', '1380554325421888');
  fbq('track', "PageView");

}

// La fonction qui relie Axeptio au lancement du cookie le Facebook Pixel et à Google Analytics

void 0 === window._axcb && (window._axcb = []);
window._axcb.push(function(axeptio) {
      axeptio.on("cookies:complete", function (choices) {
        if (choices.facebook_pixel) {
          launchFB();
        }
        if (choices.google_analytics) {
          launchGoogleAnalytics();
        }
      });
    })
  `}
    </script>
  );

  return (
    <Helmet>
      <html lang={intl.locale} />

      <title>{pageTitle}</title>

      <link rel="icon" type="image/png" href={favicon} />
      <link rel="shortcut icon" type="image/png" href={favicon} />

      <meta name="viewport" content="width=device-width, initial-scale=1.0 maximum-scale=1.0, user-scalable=0" />
      <meta name="description" content={metaDescription} />
      <meta name="facebook-domain-verification" content="1kdc90r4pky1pruv778vajdgv94epa" />
      <meta name="img" content={metaImg} />

      {/* Facebook/Telegram (OpenGraph) */}

      <meta property="fb:app_id" content="524114344381954" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:site_name" content="Onoff" />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={metaImg} />
      <meta property="og:url" content={site.siteMetadata.siteURL} />

      {/* Twitter */}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaImg} />
      <meta name="twitter:creator" content={intl.formatMessage({ id: 'meta.author' })} />
      <meta name="twitter:site" content={intl.formatMessage({ id: 'meta.author' })} />

      {/* Fonts */}

      <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin="anonymous" />
      <link href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap" rel="stylesheet" />
      {chooseCookies}
    </Helmet>
  );
};

export default SEO;
